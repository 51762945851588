import React, { useState, useEffect } from 'react';
import { func, bool } from 'prop-types';

import { Box, Text } from '@chakra-ui/react';
import useInvitation from '@hooks/useInvitation';
import WithAnimation from '@/components/Common/WithAnimation';

import playMusic from '@/components/Common/FloatingMusic/utils/playMusic';
import Image from '@/components/Common/LazyImage';
import ScrollToDown from '@components/Common/ScrollToDown';

import { THE_BRIDE, WEDDING_DATE } from '@/constants';
import { BG_PRIMARY } from '@/constants/colors';
import { BG_COVER, IMG_LOGO } from '@/constants/assets';

/**
 * Function to render Component CoverV3
 * This component will be dissapear after user click show `Undangan`
 * @return {JSX.Element}
 */
function Cover({ loaded, onSeeDetail }) {
  const [loading, setLoading] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const isInvitation = useInvitation();

  const handleClickDetail = () => {
    if (loading) return undefined;

    playMusic();
    
    setLoading(true);
    setTimeout(() => {
      onSeeDetail();
      setLoading(false);
      setShowTitle(false);
    }, 1500);
  };

  useEffect(() => {
    setShowTitle(true);
  }, []);

  return (
    <Box minHeight="100vh" backgroundColor={BG_PRIMARY}>
      <Box
        height="100vh"
        width="100%"
        bgSize="cover"
        bgPosition="center"
        bgImage={`url(${BG_COVER})`}
      >
        <Box position="absolute" width="100%" maxWidth="500px">
          <Image src={IMG_LOGO} maxWidth="60px" position="absolute" right="8px" top="8px" />
        </Box>
      </Box>
      {showTitle && (
        <Box
          bottom="4rem"
          position="absolute"
          color="bgPrimary"
          letterSpacing="1px"
          fontWeight="400"
          fontSize="10px"
          width="100%"
          maxW="500px"
          top="calc(100vh - 200px)"
        >
          <Box>
            {!isInvitation && (
              <WithAnimation>
                <Text
                  textAlign="center"
                  fontSize="lg"
                  color="bgPrimary"
                  textTransform="uppercase"
                  letterSpacing="2px"
                  mt="-300px"
                  pb="280px"
                >
                  WEDDING ANNOUNCEMENT
                </Text>
              </WithAnimation>
            )}
            <WithAnimation>
              <Text
                textAlign="center"
                fontSize="2xl"
                color="bgPrimary"
                textTransform="uppercase"
                letterSpacing="2px"
              >
                {THE_BRIDE}
              </Text>
            </WithAnimation>
            <WithAnimation>
              <Text
                textAlign="center"
                fontSize="lg"
                color="bgPrimary"
                letterSpacing="2px"
              >
               #GieNezRecords
              </Text>
            </WithAnimation>
            <WithAnimation>
              <Text
                textAlign="center"
                fontSize="lg"
                color="bgPrimary"
                textTransform="uppercase"
                letterSpacing="2px"
              >
                {WEDDING_DATE}
              </Text>
            </WithAnimation>
            <ScrollToDown
              loaded={loaded}
              loading={loading}
              marginTop="16px"
              onClick={handleClickDetail}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

Cover.propTypes = {
  loaded: bool.isRequired,
  onSeeDetail: func.isRequired,
};

export default Cover;
