import get from '@invitato/helpers/dist/getLocale';

export default {
  dear: get('Kepada Bapak/Ibu/Saudara/i,', 'Dear Mr/Mrs/Ms,'),
  friend: get('Keluarga & Teman-teman', 'Family & Friends'),
  announcement: get(
    `Sebuah kehormatan bagi kami dapat mengumumkan kabar bahagia pada hari ini.`,
    `We are pleased to announce our upcoming wedding to the family and friends.`,
  ),
  ayat: get(
    `Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu isteri-isteri dari jenismu sendiri, supaya kamu cenderung dan merasa tenteram kepadanya, dan dijadikan-Nya diantaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda bagi kaum yang berfikir.`,
    `With all humility and gentleness, with patience, bearing with one another in love, eager to maintain the unity of the Spirit in the bond of peace`,
  ),
  ayatName: get('(QS. Ar-Rum: 21)', '(Ephesians 4:2-3)'),
};