import React from 'react';
import { bool, func } from 'prop-types';
import { Center, Box, Spinner, IconButton } from '@chakra-ui/react';
import { HiOutlineChevronDoubleDown } from 'react-icons/hi';

import txtWording from './locales';
import { DEFAULT_LANGUAGE as lang } from '@/constants/feature-flags';

/**
 * function to render button scroll down
 * @returns {JSX.Element}
 */
function ScrollToDown({ loaded, loading, onClick, ...rest }) {
  if (loading) {
    return (
      <Center>
        <Spinner marginTop="16px" color="bgSecondary" />
      </Center>
    );
  }

  return (
    <Box>
      <Center>
        <IconButton
          {...rest}
          variant="outline"
          colorScheme='blackAlpha'
          size="sm"
          className="animation-up-and-down"
          isRound
          icon={<HiOutlineChevronDoubleDown color="black" />}
          onClick={onClick}
        />
      </Center>
      {!loaded && (
        <Box marginTop="8px" textAlign="center" color="bgPrimary" fontSize="sm">
          {txtWording.buttonSubtitle[lang]}
        </Box>
      )}
    </Box>
  );
}

ScrollToDown.propTypes = {
  loaded: bool.isRequired,
  loading: bool.isRequired,
  onClick: func.isRequired,
};

export default React.memo(ScrollToDown);
