import React from 'react';
import { bool, func } from 'prop-types';
import { BiArrowBack } from 'react-icons/bi';
import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  Text,
  AspectRatio
} from '@chakra-ui/react';

import loadable from '@loadable/component';
import useInvitation from '@/hooks/useInvitation';

import { DEFAULT_LANGUAGE as lang, ENABLE_VIDEO_PREWEDDING } from '@/constants/feature-flags';
import { PREWEDDING_EMBED } from '@constants/index';
import { IMG_COVER } from '@/constants/assets';

import txtWording from './locales'
import { photos as photosCompassion } from './api/photos-compassion';

const LoadableGallerry = loadable(() =>
  import(/* webpackChunkName: "invitato-gallery-photos" */ './Gallery'),
);

function PhotoContent({ isOpen, onClose }) {
  const invitation = useInvitation();

  return (
    <Drawer size="full" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent maxW="500px">
        <DrawerCloseButton color="transparent" />
        <DrawerBody width="100%" padding="0">
          <Box bgImage={`url(${IMG_COVER})`} height="450px" bgSize="cover" bgPosition="center" />
          {/* Images Cover */}
          <Box
            padding="32px"
            paddingTop="32px"
            textAlign="center"
            color="secondaryColorText"
            fontFamily="serif"
          >
            <Text fontSize="xl">
              {`“Love doesn't make the world go round. Love is what makes the ride worthwhile.”`}
              – Franklin P. Jones
            </Text>
            {/* Prewedding Video */}
            {ENABLE_VIDEO_PREWEDDING && (
              <Box padding="32px 0">
                <AspectRatio maxW="560px" ratio={16 / 9} borderRadius="lg">
                  <iframe
                    title='Prewedding'
                    src={PREWEDDING_EMBED}
                    allowFullScreen
                    loading="lazy"
                    style={{ borderRadius: '16px' }}
                  />
                </AspectRatio>
              </Box>
            )}
          <Box paddingTop="42px">
            <Box marginTop="24px">{isOpen && <LoadableGallerry data={photosCompassion} />}</Box>
          </Box>
          </Box>
          <Center padding="16px 0 48px 0">
            <Button
              leftIcon={<BiArrowBack />}
              size="sm"
              fontWeight="light"
              color="secondaryColorText"
              variant="outline"
              colorScheme="blackAlpha"
              onClick={() => onClose()}
            >
              {invitation
                ? <>{txtWording.backToInvitation[lang]}</>
                : <>{txtWording.backToAnnouncement[lang]}</>
              }
            </Button>
          </Center>
          <Box height="50px" />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

PhotoContent.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default PhotoContent;